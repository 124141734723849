import React from "react";
import androidButtonEn from '../images/download-btns/google_play_badge.png';
import { useIntl } from "react-intl";
import { OutboundLink } from "gatsby-plugin-google-gtag";

function DownloadAndroidButton() {
  const intl = useIntl();

    return (
        <OutboundLink href="https://play.google.com/store/apps/details?id=app.describle.app">
            <img
                src={androidButtonEn}
                className="w-60 transform duration-200 hover:scale-105"
                alt={intl.formatMessage({ id: 'download_on_android' })}
            />
        </OutboundLink>

    );
}


export default DownloadAndroidButton;
