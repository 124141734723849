import { useState, useEffect } from 'react';


export default function (durationMs, interval = 1000) {
  const [timeLeft, setTimeLeft] = useState(durationMs);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - interval);
      }, interval);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [interval, timeLeft]);

  return timeLeft;
}
