import React from "react";
import iosButtonEn from '../images/download-btns/app_store_badge.png';
import { useIntl } from "react-intl";
import { OutboundLink } from "gatsby-plugin-google-gtag";

function DownloadIosButton() {
  const intl = useIntl();

    return (
        <OutboundLink href="https://apps.apple.com/nl/app/describle/id1544181186">
            <img
                src={iosButtonEn}
                className="w-60 transform duration-200 hover:scale-105"
                alt={intl.formatMessage({ id: 'download_on_ios' })}
            />
        </OutboundLink>

    );
}


export default DownloadIosButton;
