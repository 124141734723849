import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import tailwindConfig from '../../tailwind.config';
import useWindowSize from '../hooks/useWindowSize'
import PropTypes from 'prop-types'

// interface TimerRoundProps {
// 	time: number;
// 	timeMs: number;
// 	max: number;
// 	text?: string;
// }


const determineMaxHeight = (windowWidth) => {
	if (windowWidth >= 1024) {
		return '110px';
	}

	if (windowWidth >= 712) {
		return '75px';
	}

	if (windowWidth > 500) {
		return '62px'
	}

	return '55px';
}

const TimerRound = (props) => {
	const size = useWindowSize();

	const maxMs = props.max * 1000;
	return (
		<div style={{ padding: '10px 0' }}>
			<CircularProgressbar
				value={maxMs - props.timeMs}
				maxValue={maxMs}
				text={props.text || String(Math.ceil(props.timeMs / 1000))}
				styles={{
					root: {
						maxHeight: determineMaxHeight(size.width)
					},
					text: {
						fontSize: props.text ? '11px' : '15px',
						fill: '#ffffff',
						fontWeight: 500,
					},
					path: {
						stroke: tailwindConfig.theme.extend.colors.secondary
					}
				}}
			/>
		</div>
	)
};

TimerRound.propTypes = {
	time: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	timeMs: PropTypes.number.isRequired,
	text: PropTypes.string,
}

export default TimerRound;
