import React from "react";

import Layout from "../layouts/defaultLayout";
import SEO from "../components/seo";
import Landing from "../components/partials/index/landing";
import Newsletter from "../components/partials/index/newsletter";
import Contact from "../components/partials/index/contact";
import { useIntl } from "react-intl";

function IndexPage() {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        keywords={[
          `Describle`,
          `app`,
          '30',
          'seconds',
          intl.formatMessage({ id: 'meta_game' }),
          'trivia',
          intl.formatMessage({ id: 'meta_questions' }),
          intl.formatMessage({ id: 'meta_answer' }),
          intl.formatMessage({ id: 'meta_five' }),
          intl.formatMessage({ id: 'meta_time' }),
          intl.formatMessage({ id: 'meta_describe' }),
        ]}
        description={intl.formatMessage({ id: 'meta_description' })}
        title="Describle"

        lang={intl.locale}
      />
      <Landing />
      <Newsletter />
      <Contact />
    </Layout>
  );
}

export default IndexPage;
