import React from "react";
import { useIntl } from "react-intl";

function Contact() {
  const intl = useIntl();

  return (
    <div>
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h2 className="text-2xl font-extrabold text-secondary sm:text-3xl">
              {intl.formatMessage({ id: 'order_questions' })}
            </h2>
            <div className="mt-3">
              <p className="text-lg text-white">
                {intl.formatMessage({ id: 'order_questions_explanation' })}
              </p>
            </div>
            <div className="mt-9">
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <div className="ml-3 text-base text-white">
                  <p>
                    <a href="mailto:support@describle.app" aria-label={intl.formatMessage({ id: 'support_email_description' })}>
                      support@describle.app
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0">
            <h2 className="text-2xl font-extrabold text-secondary sm:text-3xl">
              {intl.formatMessage({ id: 'general_questions' })}
            </h2>
            <div className="mt-3">
              <p className="text-lg text-white">
                {intl.formatMessage({ id: 'general_questions_explanation' })}
              </p>
            </div>
            <div className="mt-9">
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <div className="ml-3 text-base text-white">
                  <a href="mailto:contact@describle.app" aria-label={intl.formatMessage({ id: 'contact_email_description' })}>
                    contact@describle.app
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Contact;
