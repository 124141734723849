import React from "react";
import DownloadAndroidButton from "../../downloadAndroidButton";
import DownloadIosButton from "../../downloadIosButton";
import {useIntl} from "gatsby-plugin-intl"
import Game from "./game";

function Landing() {
    const intl = useIntl()

    return (
        <div className="relative p-4 mx-auto md:px-8 md:py-16">
            <div className="lg:relative">
                <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-36 lg:text-left">
                    <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                            <span className="block text-secondary pt-3">Describle</span>
                        </h1>
                        <p className="mt-3 max-w-md mx-auto text-lg text-white sm:text-xl md:mt-5 md:max-w-3xl">
                            {intl.formatMessage({id: "intro"})}
                        </p>
                        <div className="mt-12 md:mt-16 flex justify-center lg:justify-start flex-wrap sm:flex-nowrap mb-12 md:mb-32">
                              <div className="h-32 sm:h-auto sm:m-0">
                                  <DownloadIosButton/>
                              </div>
                            <div style={{ flexBasis: '100%' }} className="h-0 sm:hidden"/>
                            <div className="sm:ml-8">
                                <DownloadAndroidButton/>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="relative w-full h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-3/4 mb-8">
                    <Game/>
                </div>
            </div>
        </div>
    );
}

export default Landing;
