import React, { useState } from "react";
import TimerRound from "../../timerRound";
import Timer from "../../timer";
import { useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"


const query = graphql`
  query {
    file(relativePath: { eq: "iphone-transparent.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

function Game({ data }) {
  const [timedOut, setTimedOut] = useState(false);
  const intl = useIntl();
  const { file } = useStaticQuery(query);

  const questions = [
    intl.formatMessage({ id: 'game_question_1' }),
    intl.formatMessage({ id: 'game_question_2' }),
    intl.formatMessage({ id: 'game_question_3' }),
    intl.formatMessage({ id: 'game_question_4' }),
    intl.formatMessage({ id: 'game_question_5' }),
  ];

  return (
    <>
      <Img
        className="inset-0"
        objectFit="contain"
        fixed={file.childImageSharp.fixed}
        style={{ position: 'absolute', width: '100%', height: '100%' }}
        alt="Describle mobile application"
      />
      <div className="relative inset-0 w-full h-full text-center">
        <div className="pt-7 lg:pt-12">
          <span className="text-white text-xs md:text-lg block font-medium">
              {intl.formatMessage({ id: 'team_1' })}
          </span>
          <span className="text-white text-xs">
              {intl.formatMessage({ id: 'round_1' })}
          </span>
          {timedOut ? (
            <TimerRound
              time={1}
              timeMs={0}
              max={1}
              text={intl.formatMessage({ id: 'time_is_up' })}
            />
          ) : (
            <Timer
              duration={30}
              callback={() => setTimedOut(true)}
              interval={100}
            >
              {(props) => (
                <TimerRound
                  time={props.time}
                  timeMs={props.timeMs}
                  max={30}
                />
              )}
            </Timer>
          )}
        </div>
        <div className="text-left text-white mx-auto w-36 md:w-36 lg:w-52 mt-4">
          {questions.map(question => (
            <div
              className="flex flex-row items-center py-1"
              key={question}
            >
              <input
                type="checkbox"
                id={`${question}_checkbox`}
                className="rounded-full text-secondary w-4 h-4 md:w-5 md:h-5 focus:ring-2 focus:ring-secondary-accent"
              />
              <div className="ml-2 md:text-xs lg:text-base text-xs border-b border-gray-300 flex flex-grow">
                <label htmlFor={`${question}_checkbox`}>
                  {question}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Game;
