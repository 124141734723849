import { useEffect } from 'react';
import useTimer from "../hooks/useTimer";
//
// interface TimeProps {
//   timeMs: number;
//   time: number;
// }
//
// interface TimerProps {
//   duration: number;
//   interval?: number;
//   callback?: () => void;
//
//   children (props: TimeProps): JSX.Element;
// }

const Timer = (props) => {
  const time = useTimer(props.duration * 1000, props.interval);
  useEffect(() => {
    if (time <= 0 && props.callback !== undefined) {
      props.callback();
    }
  }, [props, time]);


  return props.children({ time: Math.floor(time / 1000), timeMs: time });
};

export default Timer;
