// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  purge: ["./src/**/*.js"],
  theme: {
    extend: {
      colors: {
        primary: '#03A9F4',
        'primary-accent': '#3B82F6',
        secondary: '#FCD34D',
        'secondary-accent': '#F59E0B',
      }
    },
  },
  variants: {
    extend: {
      cursor: ['disabled']
    }
  },
  // variants: {},
  // // https://github.com/tailwindcss/custom-forms
  // plugins: [require("@tailwindcss/custom-forms")],
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
  ]
};
